import React from "react";

import "./Header.css";
import { SubHeading } from "../../components";
import headerImg from "../../assets/headerImg.png";

const Header = () => (
  <div className="app__header app__wrapper section__padding" id="home">
    <div className="app__wrapper_info">
      <SubHeading title="Yeni Lezzetin Peşinde" />
      <h1 className="app__header-h1">Kaliteli Yemeğin Anahtarı</h1>
      <p className="p__opensans" style={{ margin: "2rem 0" }}>
        Lezzetin ve kalitenin buluştuğu yer. Şeflerimizin özel tarifleriyle
        unutulmaz bir yemek deneyimi yaşayın.
      </p>
      <button type="button" className="custom__button">
        <a target="_blank" href="https://cankayamenuqr.surge.sh">
          Menüyü Keşfet
        </a>
      </button>
    </div>

    <div className="app__wrapper_img">
      <img src={headerImg} alt="header_img" />
    </div>
  </div>
);

export default Header;
